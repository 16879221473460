// Utils
import { isNil } from 'lodash'

// Request
import { doRequest } from '@/utils/requestApi'

/**
 * Crea nuevo intento de fusión de 2 establecimientos
 *
 * @param {Object} data - datos del nuevo registro
 * @param {String} id - UID del registro en BD
 */
export const createFusionPlace = async (data, id = null) => {
  const result = await doRequest({
    method: 'post',
    url: isNil(id) ? 'v1/fusion-place' : `v1/fusion-place/${id}`,
    data: {
      data
    }
  })

  return result.data
}

/**
 * Obtenemos el registro de intento de fusión de establecimiento, mediante su UID
 *
 * @param {String} id - UID del intento de fusión
 * @return {Object} - datos de la fusión
 */
export const getFusionPlaceById = async (id) => {
  const result = await doRequest({
    method: 'get',
    url: `v1/fusion-place/${id}`
  })

  return result.data
}

/**
 * Obtenemos los establecimientos pendiente de fusión, mediante el UID
 * del usuario que comenzó la petición
 *
 * @param {id} id - UID user
 * @return {array} - listado de los establecimiendos pendientes de fusión
 */
export async function getEveryFusionPlacesPendingByUserId(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/fusion-place/user/${id}`
  })

  return result.data
}

/**
 * Update fusion Place
 *
 * @param {Object} data
 */
export const updateFusionPlaceById = async (data) => {
  const { id, ...params } = data
  const result = await doRequest({
    method: 'patch',
    url: `v1/fusion-place/${id}`,
    data: {
      data: params
    }
  })

  return result.data
}

/**
 * Delete Fusion Place
 *
 * @param {string} id
 */
export async function deleteFusionPlaceById(id) {
  const result = await doRequest({
    method: 'delete',
    url: `v1/fusion-place/${id}`
  })

  return result.data
}
