<template>
  <div class="multi-place-fusion">
    <!-- ToolBar -->
    <VuetifyToolBar title="Fusionar cuentas" :hide-back-button="false" :hide-action-button="true" />
    <!-- Content -->
    <v-container>
      <CardContainer>
        <v-form novalidate @submit.prevent="onSubmit">
          <!-- Información -->
          <CardContainerElement>
            <template v-slot:title>Pasos a seguir</template>
            <template v-slot:subtitle>
              <ol class="caption mb-4">
                <li>
                  Introduce la dirección de email desde donde deseas fusionar el establecimiento y
                  pulsa en "Buscar", realizaremos una búsqueda de los establecimientos asociados a
                  este email.
                </li>
                <li>
                  Selecciona el establecimiento que deseas fusionar a tu cuenta actual, ten en
                  cuenta que
                  <u>sólo podrás seleccionar un establecimiento por vez</u>. Pulsa en "Iniciar
                  proceso".
                </li>
                <li>
                  Un correo será enviado al email indicado para comenzar con el proceso de fusión
                  del establecimiento seleccionado.
                </li>
              </ol>
            </template>
            <template v-slot:content>
              <v-row dense>
                <v-col cols="12" md="9" sm="9">
                  <!-- Email -->
                  <v-text-field
                    v-model="$v.formFields.email.$model"
                    label="Email"
                    type="text"
                    outlined
                    dense
                    color="grey"
                    :hide-details="!checkFieldErrors('email').length"
                    :error-messages="checkFieldErrors('email')"
                    @input="touchField('email')"
                    @blur="touchField('email')"
                  />
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <!-- Boton cargar places -->
                  <v-btn
                    :loading="processingRequest"
                    block
                    class="elevation-0"
                    color="primary"
                    @click="handleFindPlaces"
                  >
                    Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </CardContainerElement>
          <!-- Establecimientos -->
          <CardContainerElement v-if="availablePlaces.length">
            <template v-slot:title>Establecimientos asociados</template>
            <template v-slot:subtitle>
              Selecciona el establecimiento que deseas traer a la cuenta actual con la que estás
              logado, recuerda que solo se puede importar un establecimiento cada vez.
            </template>
            <template v-slot:content>
              <!-- Radio group -->
              <v-radio-group
                v-model="$v.formFields.placeSelected.$model"
                class="mt-0"
                :hide-details="!checkFieldErrors('placeSelected').length"
                :error-messages="checkFieldErrors('placeSelected')"
                @input="touchField('placeSelected')"
                @blur="touchField('placeSelected')"
              >
                <v-radio
                  v-for="place in availablePlaces"
                  :key="place.value"
                  :label="place.label"
                  :value="place.value"
                  :disabled="place.disabled"
                ></v-radio>
              </v-radio-group>
            </template>
          </CardContainerElement>
          <!-- Action buttons -->
          <FormButtons
            accept-button-text="Iniciar proceso"
            :accept-button-loading="formProcessing"
            @onClickCancelButton="handleCancelButton"
          />
        </v-form>
      </CardContainer>
    </v-container>
  </div>
</template>

<script>
// Constants
import { ADDONS, URL_APP_HORECA } from '@/constants'
import { FUSION_PLACES_STATUS } from '../constants'
// Components
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import FormButtons from '@/components/ui/FormButtons'
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// Services
import { sendAnySendinblueEmail } from '@/services/email'
import { getUserById, getUserByEmail } from '@/services/user'
import { getEveryPlacesByIds } from '@/services/place'
import { getEveryFusionPlacesPendingByUserId, createFusionPlace } from '../services/fusionPlaces'
// Utils
import { get } from 'lodash'
import { getCurrentBrand, getEnvironmentVariable } from '@/utils'

export default {
  name: 'MultiPlacesFusion',
  components: {
    FormButtons,
    VuetifyToolBar,
    CardContainer,
    CardContainerElement
  },
  mixins: [formMixin, uiMixin, validationMixin],
  data() {
    return {
      // Form
      formFields: {
        email: '',
        placeSelected: null
      },
      formFieldsValidations: {
        email: {
          required: 'Campo obligatorio',
          email: 'No es un formato de email'
        },
        placeSelected: {
          required: 'Campo obligatorio'
        }
      },
      // Others
      userPlaces: [],
      fusionPlaces: [],
      processingRequest: false,
      userAuthTargetData: null
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData']),
    /**
     * Establecimientos disponibles para seleccionar
     *
     * @return {Array}
     */
    availablePlaces() {
      return this.userPlaces.map((place) => {
        // Se detecta en que estado esta la fusion
        const currentFusionPlace = this.fusionPlaces.find((fusion) => {
          return (
            fusion.status === FUSION_PLACES_STATUS.pending && place.id === fusion.placeKeyTarget
          )
        })
        return {
          value: place.id,
          label: place.name,
          disabled: Boolean(currentFusionPlace)
        }
      })
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    ...mapActions('authentication', ['updateUser']),
    ...mapActions('company', ['updateCompany']),
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Se vuelve a la pagina anterior
     */
    handleCancelButton() {
      this.routerGoTo()
    },
    /**
     * Obtiene la lista de establecimientos del email indicado
     */
    async handleFindPlaces() {
      this.processingRequest = true

      try {
        // Obtenemos datos del email (usuario) indicado
        this.userAuthTargetData = await getUserByEmail(this.formFields.email)
        const userTargetData = await getUserById(this.userAuthTargetData.uid)

        // Se comprueba que el usuario pertenezca al brand actual
        if (getCurrentBrand(userTargetData.brand) !== getEnvironmentVariable('VUE_APP_BRAND')) {
          throw new Error('El email indicado no existe')
        }

        // Se obtienen los establecimientos del email (usuario) indicado
        this.userPlaces = await this.getUserPlaces(Object.keys(userTargetData.places))

        if (this.userPlaces.length === 0) {
          throw new Error('El email indicado no posee establecimientos')
        }
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Obtenemos todas los datos necesarios
     * para montar el componente desde un inicio
     */
    async getEveryNeededData() {
      try {
        this.fusionPlaces = await this.getFusionPlaces()
      } catch (error) {
        this.handleError(error.message)
      }
    },
    /**
     * Evento de formulario
     */
    async afterSubmit() {
      try {
        // Datos del establecimiento seleccionado
        const placeDataSelected = this.userPlaces.find(
          (place) => place.id === this.formFields.placeSelected
        )
        // Se crea una nueva fusión
        const { fusionPlace } = await createFusionPlace({
          userKeySource: this.userData.id,
          emailSource: this.userData.email,
          userKeyTarget: this.userAuthTargetData.uid,
          placeKeyTarget: this.formFields.placeSelected,
          emailTarget: this.userAuthTargetData.email
        })

        // Se envia un email al correo introducido en el input
        await sendAnySendinblueEmail({
          method: 'sendEmailFusionPlacesValidate',
          email: this.userAuthTargetData.email,
          name: placeDataSelected.name,
          customParams: {
            endpoint: `${URL_APP_HORECA}multi-places-endpoint/${fusionPlace.id}`
          }
        })

        // Se indica que el proceso fue correcto
        this.modifyAppAlert({
          text: 'Proceso iniciado, deberá terminar de completarlo siguiendo las intrucciónes que han sido enviadas al email indicado',
          visible: true
        })
        // Se redirije a la lista de establecimientos
        this.routerPushTo({ name: 'multiPlacesList' })
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.formProcessing = false
        this.formFields.placeSelected = null
      }
    },
    /**
     * Se obtiene todos los places del usuario indicado
     *
     * @param {Array} ids - identificadores de establecimientos
     * @return {Array}
     */
    async getUserPlaces(ids) {
      return (
        (await getEveryPlacesByIds(ids))
          // Parsemos los datos de las configuraciones
          .map((place) => {
            const addOnConfigs = get(place, 'addOnConfigs', [])
            const configData = addOnConfigs.find((config) => config.id === ADDONS.place)
            return {
              ...place,
              name: configData.configFields.name || place.name
            }
          })
          // Filtramos para evitar mezclar establecimientos de distintos "Brand"
          .filter(
            (place) => getCurrentBrand(place.brand) === getEnvironmentVariable('VUE_APP_BRAND')
          )
      )
    },
    /**
     * Se obtiene todas las fusiones pendientes y se formatea el resultado
     *
     * @return {Array}
     */
    async getFusionPlaces() {
      // Se obtiene todas las fusiones pendientes
      const places = await getEveryFusionPlacesPendingByUserId(this.userData.id)

      return places
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      email: { required, email },
      placeSelected: { required }
    }
  }
}
</script>
<style lang="scss">
.multi-place-fusion {
  height: 100%;
}
</style>
